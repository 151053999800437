<script lang="ts">
  import { type Snippet } from "svelte"
  import type { LayoutData } from "./$types"
  import { SiteFooter, SiteHeader } from "$lib/components/centralci/index.js"

  let { children, data }: { children: Snippet<[]>; data: LayoutData } = $props()

  const { session, rich_user } = data
</script>

<div class="min-h-screen bg-background font-sans antialiased flex flex-col">
  <SiteHeader {rich_user} />
  <div class="mx-auto flex flex-1 overflow-hidden self-stretch size-full">
    {@render children()}
  </div>
  <SiteFooter />
</div>
